import { FC } from 'react';
import {
  PublicGetCityPageResponse,
  SearchResult,
} from '@les-sherpas/sherpas-toolbox';
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import server from 'config';
import { GetStaticPaths, GetStaticPathsResult } from 'next';
import Head from 'next/head';

import getStructuredData from '@/components/LandingMozza/LandingSeo/LandingCity/getStructuredData';
import LandingCity from '@/components/LandingMozza/LandingSeo/LandingCity/LandingCity';
import { apiGetSubjects } from '@/shared/API/apiGetSubjects';
import { useCanonicalUrl } from '@/shared/Hooks/useCanonicalUrl';
import { getListTeachers } from '@/utils/Seo.utils';

type Props = PublicGetCityPageResponse & {
  announces: SearchResult[];
};
const LandingSeoCityPage: FC<Props> = ({
  template,
  announces,
  cityAddress,
  links,
}) => {
  const canonicalUrl = useCanonicalUrl();

  return (
    <>
      <Head>
        <title>{template.meta.title}</title>
        <meta name="description" content={template.meta.description} />
        <link rel="canonical" href={canonicalUrl} />
        <script
          key="structured-data"
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: getStructuredData({
              canonicalUrl,
              meta: {
                title: template.meta.title,
                description: template.meta.description,
              },
              cityAddress: {
                city: cityAddress.city,
                zipCode: cityAddress.zipCode,
                latitude: cityAddress.latitude.toString(),
                longitude: cityAddress.longitude.toString(),
              },
            }),
          }}
        />
      </Head>
      <LandingCity
        template={template}
        announces={announces}
        cityAddress={cityAddress}
        links={links}
      />
    </>
  );
};

export const getStaticPaths: GetStaticPaths = (): GetStaticPathsResult => ({
  paths: [{ params: { city: 'paris' } }],
  fallback: 'blocking',
});

export async function getStaticProps({ params }) {
  const { city } = params;
  const queryClient = new QueryClient();

  try {
    const { data } = await axios.get<PublicGetCityPageResponse>(
      `${server}seo-student-pages/public/get-city-page/${city}`,
      {
        validateStatus: (status) => status === 200,
      }
    );
    const [announces] = await Promise.all([
      getListTeachers('', '', data.cityAddress, 8),
      queryClient.prefetchQuery(['subject'], apiGetSubjects),
    ]);

    return {
      props: {
        dehydratedState: dehydrate(queryClient),
        announces,
        ...data,
        isTvBannerDisplayed: false,
      } satisfies Props & {
        isTvBannerDisplayed: boolean;
        dehydratedState: DehydratedState;
      },
      revalidate: 60 * 60 * 24,
    };
  } catch (e) {
    return {
      notFound: true,
    };
  }
}

export default LandingSeoCityPage;
